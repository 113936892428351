// src/apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const authState = getState().auth;
      const token = authState.userData?.token; // Assuming the token is stored in userData

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    globalSettingsGet: builder.query({
      query: () => ({
        url: 'global/globalSettings',
        method: 'get',
      }),
      providesTags: ['Settings'],
    }),

    globalSettings: builder.mutation({
      query: (payload) => ({
        url: '/admin1234',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
});

export const { useGlobalSettingsGetQuery, useGlobalSettingsMutation } = homeApi;
